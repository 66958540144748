<template>
  <v-card>
    <v-row>
      <v-col cols="8">
        <v-card-text>
          <v-text-field
            v-model="name"
            v-prevent-keys
            :counter="100"
            solo
            placeholder="Rule name"
          >
          </v-text-field>
          <v-textarea
            :counter="2000"
            ref="textarea"
            id="ruleinput"
            placeholder="Rule"
            v-prevent-keys
            row-height="10"
            solo
            v-model="rule"
            @input="setLatestCaretPosition()"
            @keyup="setLatestCaretPosition()"
            @click="setLatestCaretPosition()"
          >
          </v-textarea>
          <PrettyRule v-model="rule"></PrettyRule>
        </v-card-text>
      </v-col>
      <v-col cols="4" style="overflow-y: scroll;">
        Rule
        <v-card-text>
          <v-btn @click="addText('if ')" class="mt-1" x-small block>if</v-btn>
          <v-btn @click="addText('then ')" class="mt-1" x-small block>then</v-btn>
          <v-btn @click="addText('else ')" class="mt-1" x-small block>else</v-btn>
        </v-card-text>
        Tag or group
        <v-card-text>
          <v-btn @click="addingTag = true" class="mt-1" x-small block>Tag or group</v-btn>
        </v-card-text>
        Condition
        <v-card-text>
          <v-btn @click="addText('is chosen ')" class="mt-1" x-small block>[tag] is chosen</v-btn>
          <v-btn @click="addText('not chosen ')" class="mt-1" x-small block>[tag] not chosen</v-btn>
          <v-btn @click="addText('and ')" class="mt-1" x-small block>and</v-btn>
          <v-btn @click="addText('or ')" class="mt-1" x-small block>or</v-btn>
          <v-btn @click="addText('(')" class="mt-1" x-small block>(</v-btn>
          <v-btn @click="addText(')')" class="mt-1" x-small block>)</v-btn>
        </v-card-text>
        Action
        <v-card-text>
          <v-btn @click="addText('choose ')" class="mt-1" x-small block>choose [tag]</v-btn>
          <!-- <v-btn @click="addText('toggle ')" class="mt-1" x-small block>toggle [tag]</v-btn> -->
          <v-btn @click="addText('cycle_forward ')" class="mt-1" x-small block>choose next tag in group [tag group]</v-btn>
          <v-btn @click="addText('cycle_backwards ')" class="mt-1" x-small block>choose preceding tag in group [tag group]</v-btn>
          <v-btn @click="addText('preserve ')" class="mt-1" x-small block>preserve [tag group]</v-btn>
          <v-btn @click="addText('clear ')" class="mt-1" x-small block>clear [tag or tag group]</v-btn>
          <v-btn @click="addText('copy _ to _')" class="mt-1" x-small block>copy [tag group] to [tag group]</v-btn>
          <v-btn @click="addText('switch _ with _')" class="mt-1" x-small block>switch [tag group] tags with [tag group] tags</v-btn>
        </v-card-text>
        When
        <v-card-text>
          <v-btn @click="addText('when next_clip')" class="mt-1" x-small block>handled after saving</v-btn>
          <!-- <v-btn @click="addText('when next')" class="mt-1" x-small block>next clip</v-btn> -->
        </v-card-text>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn @click="$emit('close')">
        close
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="startSaveRule()">
        save
      </v-btn>
    </v-card-actions>
    <TagAdderDialog
      :isAnalysis="$route.path.includes('analysis')"
      @close="addingTag = false"
      @tag_clicked="addTag($event); addingTag = false;"
      @group_clicked="addGroup($event); addingTag = false;"
      :show="addingTag"
      :hideExtraButtons="true"
      :groupChoosable="true"
      :sport_id="sport_id"
    ></TagAdderDialog>
  </v-card>
</template>

<script>
import TagAdderDialog from '@/components/tag/TagAdderDialog.vue'
import PrettyRule from '@/components/rules/PrettyRule'

import { mapActions } from 'vuex'
export default {
  props: ['value', 'edit_rule', 'sport_id'],
  components: { TagAdderDialog, PrettyRule },
  data: () => ({
    rule: '',
    name: '',
    lastCaret: 0,
    addingTag: false
  }),
  mounted() {
    if(this.edit_rule) {
      this.rule = this.edit_rule.rule
      this.name = this.edit_rule.rule_name
    }
  },
  methods: {
    ...mapActions('rule', [
      'saveRule',
      'updateRule'
    ]),
    startSaveRule() {
      const rule = {
        rule: this.rule,
        rule_name: this.name
      }

      if(this.sport_id) {
        this.$emit('input', {...rule, id: this.edit_rule?.id})
        return
      }

      if(this.edit_rule) {
        this.updateRule({...rule, id: this.edit_rule.id, name: this.name})
          .then(() => {
            this.$emit('close')
          })
      } else {
        this.saveRule(rule)
          .then(() => {
            this.$emit('close')
          })
      }

    },
    setLatestCaretPosition() {
      this.lastCaret = document.querySelector("#ruleinput").selectionStart
    },
    addTag(tag) {
      this.addText('tag ' + tag.id + ' ')
    },
    addGroup(group) {
      this.addText('group ' + group.id + ' ')
    },
    addText(text) {
      let [start, end] = this.split(this.rule, this.lastCaret) 
      this.rule = (start || '') + (text || '') + (end || '')
      // this.$emit('input', (start || '') + (text || '') + (end || '')) 

      this.setCaretPosition('ruleinput', this.lastCaret + text.length)
      this.lastCaret = this.lastCaret + text.length
      // this.$refs.textarea.focus()
    },
    split(str, index) {
      const result = [str.slice(0, index), str.slice(index)];
      return result;
    },
    setCaretPosition(elemId, caretPos) {
        var elem = document.getElementById(elemId);

        if(elem != null) {
            if(elem.createTextRange) {
              var range = elem.createTextRange();
              range.move('character', caretPos);
              range.select();
            }
            else {
                if(elem.selectionStart) {
                  elem.focus();
                  elem.setSelectionRange(caretPos, caretPos);
                }
                else
                  elem.focus();
            }
        }

        // elem.focus();
    }
  }
}
</script>
<template>
  <v-sheet class="pa-3">
    <v-btn fab large color="primary" @click="showAddRuleDialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <div @click="openRule(rule)" v-for="rule in rules" :key="rule">
      <v-row>
        <v-col>
          <v-card-title>
            {{rule.rule_name}}
          </v-card-title>
        </v-col>
        <v-col cols="2">
          <v-btn @click="REMOVE_RULE(rule.id)" class="mt-3" fab x-small>
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showAddRuleDialog">
      <add-group-rule
        :sport_id="$route.params.sport_id"
        :edit_rule="editingRule"
        @close="showAddRuleDialog = false; editingRule = null;"
        :sport="true"
        @input="handleRuleAdd($event)"
      />
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import AddGroupRule from '../tag/createDialogs/AddGroupRule.vue'
export default {
  components: {AddGroupRule},
  data: () => ({
    showAddRuleDialog: false,
    editingRule: null
  }),
  methods: {
    ...mapMutations('template', [
      'ADD_RULE',
      'REMOVE_RULE',
      'PUT_RULE'
    ]),
    handleRuleAdd(rule) {
      if(this.editingRule) {
        this.PUT_RULE(rule)
      } else {
        this.ADD_RULE(rule); 
      }

      this.showAddRuleDialog = false; 
      this.editingRule = null;
    },
    openRule(rule) {
      this.editingRule = rule
      this.showAddRuleDialog = true
    }
  },
  computed: {
    ...mapGetters('template', [
      'rules'
    ])
  }
}
</script>